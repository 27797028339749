<template>
  <v-row>
    <v-col>
      <div class="message" :class="{ 'message--user': isUserMessage }">
        <div class="message__wrapper pa-2">
          <p class="message__author text-body-2 my-0">
            {{ message.author }}
          </p>
          <p
            class="message__content my-0 whitespace-pre-wrap"
            v-html="parseMarkdown(message.content)"
          ></p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { marked } from "marked";

export default {
  name: "ChatBubble",
  props: {
    message: {
      type: Object,
      require: true,
    },
  },
  computed: {
    isUserMessage() {
      return this.message.author !== "Bot Connect";
    },
  },
  methods: {
    parseMarkdown(content) {
      return marked.parse(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  justify-content: flex-start;
}
.message__wrapper {
  background: $color-support-brand-02;
  border-radius: $radius-rounded-d;
  max-width: 90%;
}
.message__author {
  font-weight: bold !important;
}
.message__content {
  word-break: break-word;
}
.message--user {
  justify-content: flex-end;
  .message__wrapper {
    background: $color-container-default;
  }
}
</style>
