import Vue from "vue";
import Vuex from "vuex";

import {
  createStoreModule as createDynamicModule,
  DYNAMIC_MODULE,
} from "@flyt-frontend/capability-dynamic";
import {
  createStoreModule as createSharedModule,
  SHARED_MODULE,
} from "@flyt-frontend/shared-state";
import {
  createStoreModule as createReportingModule,
  REPORTING_MODULE,
} from "@flyt-frontend/capability-reporting";
import {
  createStoreModule as createAlertingModule,
  ALERTING_MODULE,
} from "@flyt-frontend/capability-alerting";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    [SHARED_MODULE]: createSharedModule(),
    [REPORTING_MODULE]: createReportingModule(),
    [DYNAMIC_MODULE]: createDynamicModule(),
    [ALERTING_MODULE]: createAlertingModule(),
  },
});
