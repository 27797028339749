<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col class="d-flex flex-column align-center">
          <h2>403 — Not Authorised</h2>
          <p>
            You do not have permission to view this page. Please contact
            JETConnect for access.
          </p>
          <v-img
            class="mt-3 NotAuthorised__hero"
            src="/assets/images/403-Lock.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "NotAuthorised",
};
</script>

<style lang="scss" scoped>
.NotAuthorised__hero {
  width: 30vw;
  max-width: 100%;
}
</style>
