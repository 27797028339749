<template>
  <v-dialog
    v-model="dialog"
    width="600px"
  >

    <v-card>
      <slot />
      <v-card-title class="headline mb-4" v-if="hasTitle">
        <h2
          data-qa=""
          class="title"
        >
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text v-if="!isSuccess && !isError">
        <div v-for="step in steps" :key="step.id" >
          <div v-if="step.id === currentStep">
            <slot
              :name="`content${step.id}`"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-text
        v-else-if="isSuccess"
        data-qa="ops-dialog-stepper__success"
      >
        <slot
          :name="`contentsuccess`"
        />
      </v-card-text>
      <v-card-text
        v-else-if="isError"
        data-qa="ops-dialog-stepper__error"
      >
        <slot
          :name="`contenterror`"
        />
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-divider class="mb-4"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0" align="center" justify="end">
            <v-col cols="auto">
              <v-btn
                color="primary"
                outlined
                @click="close"
              >
                {{ closeButtonText }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="!lastStep"
                color="primary"
                @click="next"
                :disabled="disabledButton"
                data-qa="ops-dialog-stepper__button-next"
              >
                {{ buttonText }}
              </v-btn>
              <v-btn
                v-else-if="isSuccess || isError"
                color="primary"
                @click="close(true)"
              >
                {{ buttonText }}
              </v-btn>
              <v-btn
                v-else
                color="primary"
                @click="save"
                :disabled="disabledButton"
                data-qa="ops-dialog-stepper__button-save"
              >
                {{ saveButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OpsDialogStepper",
  data: () => {
    return {
      currentStep: 1,
      dialog: false
    }
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    toggleDialog: {
      type: Boolean,
      default: false
    },
    closeButtonText: {
      type: String,
      default: "Close"
    },
    nextButtonText: {
      type: String,
      default: "Next"
    },
    saveButtonText: {
      type: String,
      default: "Save"
    },
    disabledButton: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    toggleDialog: function(newVal) {
      this.dialog = newVal;
    },
    dialog: function(newVal) {
      if (!newVal) {
        this.currentStep = 1;
        this.$emit("close")
      }
    }
  },
  computed: {
    hasTitle: function() {
      return !!this.title.length;
    },
    lastStep: function() {
      return !this.steps[this.currentStep];
    },
    buttonText: function() {
      if (this.isSuccess || this.isError) {
        return "Done"
      } else {
        return this.nextButtonText;
      }
    }
  },
  methods: {
    close: function(emitRefreshEvent = false) {
      this.dialog = false;

      if (emitRefreshEvent) {
        this.$emit("refresh");
      }
    },
    next: function() {
      this.currentStep = this.currentStep + 1;
    },
    save: function() {
      this.$emit("save");
    }
  }
}
</script>
