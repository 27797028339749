<template>
  <div class="cookie-notifier" data-qa="cookie-notifier__wrapper">
    <v-card elevation="4" class="pa-6" data-qa="cookie-notifier__card">
      <h3 class="mb-2" data-qa="cookie-notifier__title">Cookies</h3>
      <p data-qa="cookie-notifier__text">
        We use required cookies (and similar technologies) to make our platform
        work. We'd also like to use optional cookies to help us improve our
        platform by collecting information on how you use it. For details about
        the cookies and technologies we use, see our
        <router-link :to="{ name: 'AppPrivacyPolicy' }"
          >privacy & cookie policy</router-link
        >.<br />
        Using this banner will set a cookie on your device to remember your
        preferences.
      </p>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="selectedOption('required')"
          data-qa="cookie-notifier__button--required"
        >
          Accept only required cookies
        </v-btn>
        <v-btn
          color="primary"
          @click="selectedOption('all')"
          data-qa="cookie-notifier__button--all"
        >
          Accept all cookies
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CookieNotifier",
  methods: {
    selectedOption(option = "") {
      if (!option.length) {
        return;
      }

      this.$emit("selectedCookieOption", option);
    },
  },
};
</script>

<style lang="scss">
@import "~@flytio/shared-library/styles/settings/settings";
.cookie-notifier {
  z-index: 9999;
  position: absolute;
  bottom: 385px;
  height: 0px; // Tricking Vuetify, otherwise some elements have more spacings
  left: 20px;
  max-width: 520px;
}
</style>
