<template>
  <v-app data-qa="app__wrapper">
    <template v-if="loggedIn || isPublicRoute">
      <v-app-bar app v-if="!isIndex" data-qa="app__header" class="app__header">
        <Header
          :latest-brands="latestBrands"
          data-qa="app__header-brands"
          :showSearch="loggedIn"
          @changed-brand="changeBrand"
        />
        <template v-slot:extension v-if="activeBrandSlug">
          <v-container>
            <v-tabs align-with-title background-color="white">
              <v-tab
                data-qa="app__header-tab"
                v-for="capability in capabilities"
                :key="capability.title"
                :to="{
                  name: capability.buttons[0].route,
                  params: {
                    capability: capability.capability.id,
                  },
                }"
              >
                {{ capability.title }}
              </v-tab>
            </v-tabs>
          </v-container>
        </template>
      </v-app-bar>
      <v-main class="grey lighten-3 pb-8" data-qa="app__content">
        <v-container class="fluid">
          <v-row align="center" justify="center">
            <v-col :lg="appWidth" md="12" sm="12">
              <v-alert
                type="error"
                dismissible
                data-qa="app_deprecation_warning"
              >
                Connect App will be updated at the end of September. You will be
                redirected automatically, and do not need to make any changes.
                To preview the new site before then, please visit the
                <a class="app__connect-link" href="https://connect-app.flyt.io"
                  >new Connect App</a
                >.
              </v-alert>
              <router-view />
            </v-col>
          </v-row>
        </v-container>
        <ErrorNotifier />
        <AlertNotifier />
      </v-main>
    </template>
    <template v-else>
      <Login />
    </template>
    <v-footer padless fixed bottom>
      <v-col class="text-center text-body-2" cols="12">
        <router-link :to="{ name: 'AppPrivacyPolicy' }"
          >Privacy & Cookie Policy</router-link
        >
        -
        <router-link :to="{ name: 'AppTermsAndConditions' }"
          >Terms & Conditions</router-link
        >
      </v-col>
      <CookieNotifier
        v-if="noCookieSet"
        @selectedCookieOption="cookieSelected"
        data-qa="app__cookie-notifier"
      />
    </v-footer>
    <ChatBot v-if="betaTesterUser" />
  </v-app>
</template>

<script>
import Header from "@flytio/shared-library/components/Header";
import ErrorNotifier from "@flytio/shared-library/components/ErrorNotifier";
import Login from "@flytio/shared-library/components/Login";
import AlertNotifier from "@flytio/shared-library/components/AlertNotifier";
import CookieNotifier from "@/components/CookieNotifier";
import ChatBot from "@/chat-bot/components/ChatBot.vue";
import { hasPermission } from "@flytio/shared-library/routing/domain-guard";
import { getAllBrandsFromLocalStorage } from "@flytio/shared-library/helpers/local-storage";
import {
  user,
  token,
  isAuthenticated,
} from "@flytio/shared-library/routing/auth";

const titleSite = "Flyt Connect";

export default {
  name: "App",
  components: {
    Header,
    ErrorNotifier,
    Login,
    CookieNotifier,
    AlertNotifier,
    ChatBot,
  },
  data() {
    return {
      setCookie: false,
      loggedIn: false,
      publicPath: process.env.BASE_URL,
      latestBrands: [],
    };
  },
  beforeCreate() {
    this.$store.commit("shared/GET_FAVOURITE_BRANDS");
  },
  async created() {
    await this.$store.dispatch("shared/getFeatureFlags");
  },
  async mounted() {
    if (user && isAuthenticated && token) {
      this.loggedIn = true;
      await this.$store.dispatch("shared/setUser", user);
    } else {
      this.loggedIn = false;
    }
  },
  computed: {
    activeBrandSlug() {
      return this.$store.state.shared.brand.slug;
    },
    capabilities() {
      let isOwnChannelDeliveryEnabled =
        this.$store.state.shared.flags["own-channel-delivery-capability"];
      return this.$store.state.shared.capabilities.filter((capability) => {
        if (capability.capability.id === "delivery") {
          return (
            isOwnChannelDeliveryEnabled &&
            hasPermission(user, capability.capability.id)
          );
        }
        return hasPermission(user, capability.capability.id);
      });
    },
    isProduction() {
      return process.env.VUE_APP_ENV === "production";
    },
    isE2EUser() {
      return user?.email === "e2e-fc@flyt.io";
    },
    noCookieSet() {
      if (this.isE2EUser || !this.isProduction) {
        return false;
      } else {
        return !this.$cookies.get("flyt-cookies-set");
      }
    },
    isPublicRoute() {
      return (
        this.$route.name === "AppPrivacyPolicy" ||
        this.$route.name === "AppTermsAndConditions" ||
        this.$route.name === "AppCookiePolicy"
      );
    },
    isIndex() {
      return this.$route.name ? this.$route.name === "Index" : true;
    },
    appWidth() {
      return /^reporting.*$/i.test(this.$route.name) ? 12 : 8;
    },
    betaTesterUser() {
      if (user) {
        return hasPermission(user, "beta-testing");
      } else {
        return false;
      }
    },
  },
  methods: {
    async changeBrand(brand, goHome = true) {
      let u = this.$store.state.shared.user;
      this.clearState();
      u.activeBrandSlug = brand.slug;
      await this.$store.dispatch("shared/setUser", u);
      this.latestBrands = await getAllBrandsFromLocalStorage();

      if (goHome) {
        this.goHome(brand.slug);
      }
    },
    async goHome(brandSlug) {
      this.$router
        .push({
          name: "DynamicCapabilityHome",
          params: {
            brandSlug,
            capability: "menus",
          },
        })
        .catch(() => {}); // Catch needed to avoid error in console form VueRouter (expected error)
    },
    clearState() {
      if (this.$store.state.dynamic) {
        this.$store.dispatch("dynamic/resetState");
      } else {
        console.error("Dynamic state module not loaded");
      }

      if (this.$store.state.restaurants) {
        this.$store.dispatch("restaurants/resetState");
      } else {
        console.error("Restaurants state module not loaded");
      }

      if (this.$store.state.orders) {
        this.$store.dispatch("orders/resetState");
      } else {
        console.error("Orders state module not loaded");
      }

      if (this.$store.state.menus) {
        this.$store.dispatch("menus/resetState");
      } else {
        console.error("Menus state module not loaded");
      }
    },
    cookieSelected(type) {
      let cookiesAccepted = "essential";
      this.$cookies.set("flyt-cookies-set", "true");

      if (type === "all") {
        this.$cookies.set("flyt-authorized-track", "true");
        cookiesAccepted += ",analytics";
      }
      this.$cookies.set("jet-consent-jet-connect", cookiesAccepted);

      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      window.dataLayer.push({
        event: "consent_submit",
        component: {
          id: "cookieBanner",
        },
        consent: {
          status: cookiesAccepted,
          action: "submit",
        },
      });

      window.location.reload();
    },
  },
  watch: {
    $route: (to) => {
      // Example: "Capabilities - Flyt Connect" or just "Flyt Connect"
      document.title = to.meta.title
        ? to.meta.title + " | " + titleSite
        : titleSite;
    },
  },
};
</script>

<style lang="scss">
@import "~@flytio/shared-library/styles/settings/settings";
@import "~@flytio/shared-library/styles/main.scss";
</style>

<style lang="scss">
.app__header {
  .v-toolbar__extension {
    // Override default Vuetify style, which adds a grey background
    background: white;
  }
  .v-toolbar__content {
    z-index: 100; //override the default 0 which causes the search bar to appear behind the nav tabs
  }
}
.app__connect-link {
  color: white !important;
  text-decoration: underline;
}
</style>
