import multiguard from "vue-router-multiguard";
import OpsBrandsWithAssociation from "./views/OpsBrandsWithAssociation.vue";
import OpsBrandsWithAssociationItem from "./views/OpsBrandsWithAssociationItem.vue";
import { permissionGuard } from "../route-guards";

export function createRoutes(
  path = "",
  auth = () => { }
) {
  let routes = [
    {
      path: `${path}/brand-management`,
      name: "BrandManagement",
      component: OpsBrandsWithAssociation,
      beforeEnter: multiguard([auth, (to, from, next) => {
        permissionGuard(to, from, next, "brand-management");
      }]),
    },
    {
      path: "/brand-management/:brandSlug",
      name: "OpsBrandsWithAssociationItem",
      component: OpsBrandsWithAssociationItem,
      beforeEnter: multiguard([auth, (to, from, next) => {
        permissionGuard(to, from, next, "brand-management");
      }]),
    },
  ];

  return routes;
}
