import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import VueGtm from "@gtm-support/vue2-gtm";

import App from "./App.vue";
import { store } from "./store";
import routes from "./router";
import { gtm, DataGuard } from "./route-guards";

import { initVue, vuetify } from "@flytio/shared-library";
import { initAuth0 } from "@flytio/shared-library/routing/auth";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

async function init() {
  let hasTrackingCookie = document.cookie.match(
    /^(.*;)?\s*flyt-authorized-track\s*=\s*[^;]+(.*)?$/
  );
  const router = new VueRouter({
    mode: "history",
  });

  await initAuth0(window.location.origin);

  initVue(Vue, router); // Initialises vue with a common config

  Vue.use(VueRouter);
  Vue.use(VueCookies);

  Vue.$cookies.config("760d");

  router.addRoutes(routes);
  router.beforeEach((to, from, next) => {
    DataGuard(to, from, next);
    gtm(to);
  });

  if (hasTrackingCookie) {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        Vue,
        dsn: "https://c3fec999149d45c184e8011e547c2e5e@o263154.ingest.sentry.io/5385921",
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["connect.flyt.io", "flytconnect.com"],
          }),
        ],
        logErrors: true,
        tracesSampleRate: 1.0,
      });

      Vue.use(VueGtm, {
        id: "GTM-PW2K7S8V",
        vueRouter: router,
      });
    }
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}

init();
