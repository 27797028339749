import { store } from "./store"; // used to detect if there is an active brand
import { hasPermission } from "@flytio/shared-library/routing/domain-guard";
import { user } from "@flytio/shared-library";
import { v5 as uuidv5 } from "uuid";

/**
 * Construct a dataLayer object for Google Tag Manager for each page view containing
 *  - page name (from route name in ./router.js)
 *  - user locale information
 *  - cookie consent (if available)
 *  - a v5 UUID generated from the user ID (if available)
 * @param {to} to A {@link https://v3.router.vuejs.org/api/#the-route-object | route object} from vue-router
 */
export function gtm(to) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  const pageData = {
    page: {
      name: to.name,
    },
    platform: {
      country: navigator.language.split("-")[1] || "GB",
      environment: "production",
      language: "en",
    },
  };

  const cookieConsent = document.cookie
    .split("; ")
    .find((row) => row.startsWith("jet-consent-jet-connect="))
    ?.split("=")[1];

  if (cookieConsent) {
    pageData.consent = {
      status: decodeURIComponent(cookieConsent),
    };
  }

  if (user?.id) {
    // random v4 uuid of no significance to be used in v5 uuid (below)
    const namespace = "112d9c26-1272-4857-b89e-93a5d51b934f";
    pageData.user = {
      anonymousId: uuidv5(user.id, namespace),
    };
  }

  window.dataLayer.push(pageData);
}

async function detectBrand(to) {
  const routerBrandSlug = to.params?.brandSlug;
  const activeBrandSlug = store.state.shared.brand?.slug;

  if (routerBrandSlug && routerBrandSlug !== activeBrandSlug) {
    await store.dispatch("shared/loadBrand", routerBrandSlug);
    return true;
  } else {
    return false;
  }
}

async function detectCapability(to) {
  let CAPABILITY_ID = to.params?.capability || "";
  const ACTIVE_CAPABILITY = store.state.shared.capability?.id || "";
  const NEEDS_CAPABILITY = CAPABILITY_ID.length;
  if (CAPABILITY_ID.endsWith("S")) {
    CAPABILITY_ID = CAPABILITY_ID.slice(0, -1);
  }

  if (
    (NEEDS_CAPABILITY && !ACTIVE_CAPABILITY.length) ||
    ACTIVE_CAPABILITY !== CAPABILITY_ID
  ) {
    const CAPABILITY_ITEM = store.state.shared.capabilities.find(
      (item) => item.capability?.id === CAPABILITY_ID
    );

    if (CAPABILITY_ITEM) {
      await store.dispatch(
        "shared/setSelectedCapability",
        CAPABILITY_ITEM.capability
      );
      return true;
    }
    return false;
  } else {
    console.info("detectCapability: No capability found, or already in store");
    return false;
  }
}
async function detectConnectorType(to) {
  const CONNECTOR_TYPE = to.params?.connectorType?.toUpperCase() || "";
  const ACTIVE_CONNECTOR_TYPE = store.state.shared.connectorType || "";
  const NEEDS_CONNECTOR_TYPE = CONNECTOR_TYPE.length;

  if (
    NEEDS_CONNECTOR_TYPE &&
    !ACTIVE_CONNECTOR_TYPE.length &&
    ACTIVE_CONNECTOR_TYPE !== CONNECTOR_TYPE
  ) {
    await store.dispatch("shared/setConnectorType", CONNECTOR_TYPE);
  }
}

/**
 * Check whether the connector from the URL is set as the active connector.
 * @param {*} to
 */
async function detectCurrentConnector(to) {
  const CONNECTOR_ID = to.params?.connectorName;
  const ACTIVE_CONNECTOR_ID = store.state.shared.connector?.identifier;
  const connectorsData = store.state.shared.connectorsData;
  if (
    CONNECTOR_ID && // there is a connector ID
    (!ACTIVE_CONNECTOR_ID || ACTIVE_CONNECTOR_ID !== CONNECTOR_ID) // there is no active connector, or the active connector is not the same as the one in the URL
  ) {
    for (let connectorType in connectorsData) {
      const connectors = connectorsData[connectorType];
      for (let i = 0; i < connectors.length; i++) {
        if (connectors[i].identifier === CONNECTOR_ID) {
          await store.dispatch("shared/setSelectedConnector", connectors[i]);
          break;
        }
      }
    }
  }
}

export const DataGuard = async (to, from, next) => {
  const fn = async () => {
    let newBrand = await detectBrand(to);
    let newCapability = await detectCapability(to);
    if (newBrand || newCapability) {
      await store.dispatch("shared/clearConnectorData");
      await store.dispatch("shared/getAllData");
    }
    await detectConnectorType(to);
    await detectCurrentConnector(to);

    next();
  };

  return fn();
};

// Retro-compatability for
export async function BrandDataGuard(to, from, next) {
  const ROUTER_BRAND_SLUG = to.params?.brandSlug;
  const ACTIVE_BRAND_SLUG = store.state.shared.brand.slug || "";
  const NEEDS_ACTIVE_BRAND = !!ACTIVE_BRAND_SLUG.length || false;

  if (
    (NEEDS_ACTIVE_BRAND && !ACTIVE_BRAND_SLUG.length) ||
    ROUTER_BRAND_SLUG !== ACTIVE_BRAND_SLUG
  ) {
    // No brand in store, or incorrect brand in store
    await store.dispatch("shared/loadBrand", ROUTER_BRAND_SLUG);
  }
  next();
}

export async function permissionGuard(to, from, next, permission) {
  if (hasPermission(user, permission)) {
    next();
  } else {
    next({ name: "NotAuthorised" });
  }
}
