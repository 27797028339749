import { axios } from "@flytio/shared-library";

export async function getBrandsWithAssociation(baseURL) {
  const response = await axios.get("/restaurant-brand-associations/brands", {
    baseURL
  });

  return response.data;
}

export async function createBrandWithAssociation(baseURL, name = "", tenant = "", locations = []) {
  let postData = {
    name,
    tenant,
    associatedRestaurants: locations
  }

  const response = await axios.post("/restaurant-brand-associations/brands", postData, { baseURL });
  return response;
}

export async function getRestaurantsByBrand(baseURL, brandSlug) {
  let response = await axios.get(`/restaurant-brand-associations/brands/${brandSlug}`, { baseURL })
  return response.data;
}

export async function addRestaurantsToBrand(baseURL, brandSlug, locations) {
  let response = await axios.post(`/restaurant-brand-associations/brands/${brandSlug}/restaurants`, locations, { baseURL });

  return response;
}

export async function removeRestaurantFromBrand(baseURL, brandSlug, restaurantID) {
  let response = await axios.delete(`/restaurant-brand-associations/brands/${brandSlug}/restaurants/${restaurantID}`, { baseURL });

  return response;
}
