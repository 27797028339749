<template>
  <v-container>
    <v-row>
      <v-col class="d-flex flex-column align-center">
        <h2>404 — Not Found</h2>
        <p>We could not find the page you are looking for.</p>
        <v-img
          class="mt-3 NotFound__hero"
          src="/assets/images/404-Flustered.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.NotFound__hero {
  width: 30vw;
  max-width: 100%;
}
</style>
