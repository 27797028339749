<template>
  <div class="chatBot" v-if="chatBotInitialised">
    <icon-chat-bot-large
      v-if="!showChatBot"
      @click="toggleChatBot"
      :size="40"
      class="chatBot__icon chatBot__icon--open"
    />
    <v-container
      v-if="showChatBot"
      class="chatBot__window"
      :class="{ 'chatBot__window--expanded': chatBotWindowExpanded }"
    >
      <v-row justify="space-between" class="chatBot__controls">
        <v-col cols="auto">
          <icon-fullscreen-expand-large
            v-if="!chatBotWindowExpanded"
            @click="toggleChatWindowSize"
            class="chatBot__icon"
          />
          <icon-fullscreen-exit-large
            v-if="chatBotWindowExpanded"
            @click="toggleChatWindowSize"
            class="chatBot__icon"
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <icon-close-large @click="toggleChatBot" class="chatBot__icon" />
        </v-col>
      </v-row>
      <v-row class="chatBot__messages" ref="chatBot__messages">
        <v-col v-if="chatBotError">
          <p>
            Something has gone wrong. Please refresh the page and try a new
            chat.
          </p>
        </v-col>
        <v-col v-else-if="chat">
          <chat-bubble
            v-for="(message, i) in chat.messages"
            :key="i"
            :message="message"
          />
          <div v-if="showRanking" class="chatBot__ranking">
            <icon-thumbs-up-large
              class="chatBot__icon chatBot__icon--thumb mt-2 pa-1"
              @click="sendRanking('👍')"
            />
            <icon-thumbs-down-large
              class="chatBot__icon chatBot__icon--thumb mt-2 pa-1"
              @click="sendRanking('👎')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="chatBot__input">
        <v-col>
          <v-form @submit.prevent="sendMessage">
            <v-text-field
              variant="plain"
              solo
              flat
              label="Type a message"
              hide-details
              class="chatBot__message-input"
              :loading="chatBotLoading"
              v-model="message"
            >
              <template v-slot:append>
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="
                    chatBotLoading || message.length < 1 || chatBotError
                  "
                  >Send</v-btn
                >
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  IconChatBotLarge,
  IconCloseLarge,
  IconFullscreenExpandLarge,
  IconFullscreenExitLarge,
  IconThumbsUpLarge,
  IconThumbsDownLarge,
} from "@justeattakeaway/pie-icons-vue";
import { axios } from "@flytio/shared-library";
import ChatBubble from "./ChatBubble.vue";

export default {
  name: "ChatBot",
  components: {
    IconChatBotLarge,
    IconCloseLarge,
    ChatBubble,
    IconFullscreenExpandLarge,
    IconFullscreenExitLarge,
    IconThumbsUpLarge,
    IconThumbsDownLarge,
  },
  data() {
    return {
      showChatBot: false,
      chatBotLoading: false,
      chatBotInitialised: false,
      chatBotWindowExpanded: false,
      chatBotError: false,
      message: "",
    };
  },
  computed: {
    brandSlug() {
      return this.$store.state.shared.brand.slug;
    },
    chat() {
      return this.$store.state.shared.chats[this.brandSlug] || null;
    },
    showRanking() {
      return (
        this.chat?.messages[this.chat.messages.length - 1]?.author ===
        "Bot Connect"
      );
    },
  },
  methods: {
    toggleChatBot() {
      this.showChatBot = !this.showChatBot;
    },
    toggleChatWindowSize() {
      this.chatBotWindowExpanded = !this.chatBotWindowExpanded;
    },
    async initialiseChat() {
      if (this.chatBotLoading) {
        return;
      }
      this.chatBotLoading = true;
      try {
        if (!this.chat) {
          const response = await axios.post(`/brands/${this.brandSlug}/chat`, {
            user: this.$store.state.shared.user.email,
          });
          this.$store.dispatch("shared/setChat", {
            chat: response.data,
            brandSlug: this.brandSlug,
          });
        }
        this.chatBotInitialised = true;
      } catch (e) {
        this.chatBotError = true;
        console.error(e);
      } finally {
        this.chatBotLoading = false;
      }
    },
    async sendMessage() {
      await this.$store.dispatch("shared/updateChat", {
        brandSlug: this.brandSlug,
        message: this.message,
      });
      this.message = "";
      this.$refs.chatBot__messages.scrollTop =
        this.$refs.chatBot__messages.scrollHeight;
      await this.submitChat();
    },
    async sendRanking(ranking) {
      await this.$store.dispatch("shared/updateChat", {
        brandSlug: this.brandSlug,
        message: ranking,
      });
      await this.submitChat();
    },
    async submitChat() {
      this.chatBotLoading = true;
      try {
        const response = await axios.put(
          `/brands/${this.brandSlug}/chat/${this.chat.id}`,
          this.chat
        );
        await this.$store.dispatch("shared/setChat", {
          chat: response.data,
          brandSlug: this.brandSlug,
        });
        await this.$nextTick();
        this.$refs.chatBot__messages.scrollTop =
          this.$refs.chatBot__messages.scrollHeight;
      } catch (e) {
        this.chatBotError = true;
        console.log(e);
      } finally {
        this.chatBotLoading = false;
      }
    },
  },
  watch: {
    brandSlug(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialiseChat();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chatBot {
  position: fixed;
  bottom: $spacing-h;
  right: $spacing-f;
}
.chatBot__icon {
  cursor: pointer;
}
.chatBot__icon--open {
  background: $color-container-default;
  border-radius: $radius-rounded-d;
  box-shadow: $elevation-box-shadow-01;
  color: $color-interactive-brand;
  padding: $spacing-a;
}
.chatBot__window {
  background: $color-background-subtle;
  border: 1px solid $color-border-default;
  border-radius: $radius-rounded-b;
  box-shadow: $elevation-box-shadow-01;
  display: flex;
  flex-direction: column;
  height: 450px;
  min-height: 450px;
  min-width: 350px;
  padding: $spacing-c;
  width: 350px;
}
.chatBot__window--expanded {
  height: 75vh;
  width: 45vw;
}
.chatBot__controls {
  flex: 0 1 auto;
}
.chatBot__messages {
  flex: 1 1 auto;
  overflow: auto;
}
.chatBot__input {
  flex: 0 1 auto;
}
.chatBot__message-input {
  border: 1px solid $color-border-default;
}
.chatBot__ranking {
  display: flex;
  gap: $spacing-b;
  justify-content: center;
}
.chatBot__icon--thumb {
  background: $color-container-default;
  border-radius: 50%;
}
</style>

