import multiguard from "vue-router-multiguard";

import NotFound from "@/views/NotFound.vue";
import NotAuthorised from "@/views/NotAuthorised.vue";

import { BrandDataGuard, DataGuard, permissionGuard } from "./route-guards";

import { AuthGuard } from "@flytio/shared-library/routing/auth-guard";
import { DomainGuard } from "@flytio/shared-library/routing/domain-guard";
import { createRoutes as generateReportingRoutes } from "@flyt-frontend/capability-reporting";
import { createRoutes as generateAlertingRoutes } from "@flyt-frontend/capability-alerting";
import { createRoutes as generateDynamicRoutes } from "@flyt-frontend/capability-dynamic";
import { createRoutes as generateBrandManagementRoutes } from "./brand-management/routes";
import { user } from "@flytio/shared-library/routing/auth";

function authGuard(to, from, next) {
  return AuthGuard(to, from, next, "/", "login");
}

let routes = [
  {
    path: "/",
    name: "AppHome",
    meta: {
      gtmAdditionalEventData: {
        routeCategory: "INFO",
      },
    },
    beforeEnter: (to, from, next) => {
      if (!user) {
        next();
        return;
      }

      const menuAccess = user.brands?.some((brand) =>
        brand.permissions.some((permission) => permission.includes("menus"))
      );
      if (menuAccess) {
        next("/menus");
        return;
      }

      const reportingAccess = user.brands?.some((brand) =>
        brand.permissions.some((permission) => permission.includes("reporting"))
      );
      const allBrandsAccess = user.brands?.[0].brandSlug === "*";
      if (reportingAccess && allBrandsAccess) {
        next("/reporting/je-uk-mcdonalds/orders");
        return;
      } else if (reportingAccess) {
        next(`/reporting/${user.brands?.[0].brandSlug}/orders`);
        return;
      }

      next({ name: "NotAuthorised" });
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@flytio/shared-library/components/Login"
      ),
  },
  {
    path: "/login/mbplc",
    name: "MabLogin",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@flytio/shared-library/components/Login"
      ),
  },
  {
    path: "/privacy-policy",
    name: "AppPrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "pandp" */ "./views/PrivacyPolicy"),
  },
  {
    path: "/terms-and-conditions",
    name: "AppTermsAndConditions",
    component: () =>
      import(/* webpackChunkName: "tandc" */ "./views/TermsAndConditions"),
  },
  {
    path: "/user-management",
    name: "UserManagement",
    component: () =>
      import(
        /* webpackChunkName: "usermanagement" */ "./views/UserManagement.vue"
      ),
    beforeEnter: multiguard([
      authGuard,
      (to, from, next) => {
        permissionGuard(to, from, next, "user-management");
      },
    ]),
    children: [
      {
        path: "add-user",
        name: "UserManagementAddUser",
        component: () =>
          import(
            /* webpackChunkName: "modalAddUser" */ "./components/UserInformation.vue"
          ),
      },
      {
        path: "edit-user/:email",
        name: "UserManagementEditUser",
        component: () =>
          import(
            /* webpackChunkName: "modalAddUser" */ "./components/UserInformation.vue"
          ),
      },
      {
        path: "delete-user/:email",
        name: "UserManagementDeleteUser",
        component: () => {
          return import(
            /* webpackChunkName: "modalDeleteUser" */ "./components/DeleteUser.vue"
          );
        },
      },
    ],
  },
  {
    path: "/403",
    name: "NotAuthorised",
    component: NotAuthorised,
  },
  ...generateReportingRoutes("", authGuard, DomainGuard, BrandDataGuard),
  ...generateAlertingRoutes("", authGuard, DomainGuard),
  ...generateBrandManagementRoutes("", authGuard),
  ...generateDynamicRoutes("", authGuard, DomainGuard, DataGuard),
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

export default routes;
